import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'

const Historic = ({ data }) => {
  const historic = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <GatsbySeo title="Home Page" />

      <GridContent id="introduction" mode="white" layout="--2-column">
        <div className="text-content-margin">
          <h2>{historic.introduction.title}</h2>
          <h3>{historic.introduction.sub_heading}</h3>
          <br />
          <p>{historic.introduction.body}</p>
        </div>

        <BgImage
          image={getImage(historic.introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent
        id="historic-page-1"
        layout="--2-column content-margin-top content-margin-bottom"
        mode="white-mode"
      >
        <GatsbyImage
          image={getImage(historic.page_1.image_left)}
          alt="background"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: historic.page_1.body }}
          />
        </div>
      </GridContent>

      <GridContent
        id="historic-page-2"
        mode="green-mode"
        layout="--2-column content-margin-bottom content-margin-top"
      >
        <p className="text-content-margin">{historic.page_2.body}</p>
        <GatsbyImage image={getImage(historic.page_2.image)} alt="background" />
      </GridContent>
      <GatsbyImage
        id="full-bleed-image"
        image={getImage(historic.full_image)}
        alt="background"
        style={{ maxHeight: '60vh', width: '100%' }}
      />
      <GridContent
        id="historic-page-3"
        mode="green-mode"
        layout="--2-column content-margin-top content-margin-bottom"
      >
        <GatsbyImage
          image={getImage(historic.page_3.image_right)}
          alt="background"
        />
        <div className="space-evenly">
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: historic.page_3.body }}
          />
          <br />
          <Link
            className="align-right mock-button"
            to={historic.page_3.button.link}
          >
            {historic.page_3.button.label}
          </Link>
        </div>
      </GridContent>

      <MaxiMenu />
    </Layout>
  )
}

export default Historic

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "historic" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                sub_heading
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }

              full_image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              page_1 {
                body

                image_left {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_2 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_3 {
                body
                button {
                  label
                  link
                }
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
